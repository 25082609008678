@import '../../assets/cascades/utilities';

.ICONinfo {
	filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
		brightness(88%) contrast(85%);
}

.tipsModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			width: 564px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px;

				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.contentwrap {
					display: flex;
					flex-direction: column;
					font-size: 16px;
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}
.ant-modal-mask {
	background: $background-darkest;
}

section.start {
	width: 100%;
	height: auto;
	padding: 40px 0 0;

	// WORK-IN-PROGRESS
	// Common Pad Mixin
	@media screen and (min-width: $mq-2) {
		padding: 40px 0 0;
	}
	@media screen and (min-width: $mq-3) {
		padding: 35px 0 0;
	}
	@media screen and (min-width: $mq-4) {
		padding: 45px 0 0;
	}
	@media screen and (min-width: $mq-5) {
		padding: 70px 0 0;
	}
	@media screen and (min-width: $mq-6) {
		padding: 75px 0 0;
	}

	// Common Size Mixin
	// Common Pad Mixin
	.banner {
		width: 90%;
		padding: 0 5%;
		height: auto;
		position: relative;
		display: flex;
		flex-flow: column wrap;
		max-width: $content-max-width;
		margin: 0 auto;

		@media screen and (min-width: $mq-2) {
			width: 90%;
			padding: 0 5%;
		}
		@media screen and (min-width: $mq-3) {
			width: 86%;
			padding: 0 7%;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0 7%;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0 7%;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0 7%;
		}

		h4 {
			width: 100%;
			margin: 20px 0 0;
			order: 2;
			text-align: center;
			color: $black;

			@include font04;
			@include font-light;

			@media screen and (min-width: $mq-2) {
				order: 1;
				margin: 0;
				text-align: center;
			}
			@media screen and (min-width: $mq-3) {
				text-align: center;
				@include font07;
			}
			@media screen and (min-width: $mq-4) {
				text-align: left;
				@include font08;
			}
		}

		h3 {
			width: 100%;
			color: $black;
			margin: 0 0 20px;
			order: 3;
			text-align: center;

			@include font06;

			@media screen and (min-width: $mq-2) {
				order: 2;
				text-align: center;
			}
			@media screen and (min-width: $mq-3) {
				text-align: center;
				@include font09;
			}
			@media screen and (min-width: $mq-4) {
				text-align: left;
				margin: 0 0 40px;
				@include font10;
			}
			@media screen and (min-width: $mq-5) {
				width: 55%;
			}
			@media screen and (min-width: $mq-6) {
				width: 50%;
			}
		}

		// Actions Component if needed
		// Common Size Mixin
		// Common Pad Mixin
		.actions {
			width: 100%;
			order: 4;
			margin: 0 0 40px;

			@media screen and (min-width: $mq-2) {
				order: 3;
				margin: 0 auto 210px;
				width: 330px;
				display: flex;
				justify-content: space-between;
			}
			@media screen and (min-width: $mq-3) {
				margin: 0 auto 38px;
			}
			@media screen and (min-width: $mq-4) {
				margin: 0 0 66px;
			}
			@media screen and (min-width: $mq-5) {
				margin: 0 0 60px;
			}
			@media screen and (min-width: $mq-6) {
				margin: 0 0 90px;
			}

			button.btnLogin {
				@include transition(0.3s);
				width: 100%;
				display: block;
				background-color: $blue;
				border-radius: 2px;
				color: $white;
				border: solid 2px $blue;
				text-align: center;
				height: 50px;
				// text-decoration: none;
				cursor: pointer;

				@media screen and (min-width: $mq-2) {
					width: 160px;
				}

				&:hover {
					background-color: $darkBlue;
					border: solid 2px $darkBlue;
				}
			}

			.btnRegister {
				@include transition(0.3s);
				width: calc(100% - 4px);
				display: block;
				border-radius: 2px;
				border: solid 2px $blue;
				background-color: $white;
				margin-bottom: 10px;
				text-align: center;
				color: $blue;
				height: 46px;
				text-decoration: none;
				margin-right: 12px;
				cursor: pointer;
				line-height: 46px; // TODO: Button Refatoring

				@media screen and (min-width: $mq-2) {
					width: 160px;
					margin-bottom: 0;
				}

				&:hover {
					background-color: $lightBlue;
				}
			}
		}

		// Custom Image Mixin
		.imagStart {
			width: 352px;
			height: 200px;
			position: relative;
			margin: 0 auto;
			@media screen and (min-width: $mq-2) {
				margin: 0 auto;
				width: 352px;
				height: 200px;
				margin-bottom: 50px;
			}
			@media screen and (min-width: $mq-3) {
				width: 610px;
				height: 322px;
			}
			@media screen and (min-width: $mq-4) {
				height: 330px;
				width: auto;
			}
			@media screen and (min-width: $mq-5) {
				height: 330px;
				width: 561px;
				bottom: -115px;
				right: 80px;
				position: absolute;
			}
			@media screen and (min-width: $mq-6) {
				height: 440px;
				width: 750px;
				bottom: -150px;
				right: 92px;
				position: absolute;
			}
		}
		.videowrapcover {
			position: absolute;
			z-index: 1;
			background: #000;
			@media screen and (min-width: $mq-5) {
				height: 280px;
				width: 434px;
				top: 130px;
				right: 149px;
			}
			@media screen and (min-width: $mq-6) {
				height: 373px;
				width: 578px;
				top: 85px;
				right: 177px;
			}
		}
		.videowrap {
			position: absolute;
			z-index: 2;
			@media screen and (min-width: $mq-5) {
				height: 280px;
				width: 434px;
				top: 158px;
				right: 149px;
			}
			@media screen and (min-width: $mq-6) {
				height: 373px;
				width: 578px;
				top: 130px;
				right: 177px;
			}
		}
		.videodrop {
			width: 110px;
			height: 40px;
			position: absolute;
			z-index: 3;
			@media screen and (min-width: $mq-5) {
				top: 158px;
				right: 169px;
			}
			@media screen and (min-width: $mq-6) {
				top: 415px;
				right: 409px;
			}
			// .Dropdown-control {
			// 	padding: 6px 52px 6px 10px;
			// }
		}
	}

	// Common Size Mixin
	// Common Pad Mixin
	.features-background {
		background-color: $nearlyWhite;
	}
	.features {
		width: 90%;
		padding: 30px 5% 20px;
		max-width: $content-max-width;
		margin: 0 auto;

		// Use weird size Mixin
		@media screen and (min-width: $mq-2) {
			width: 90%;
			padding: 130px 5% 50px;
		}
		@media screen and (min-width: $mq-3) {
			width: 86%;
			padding: 50px 7% 50px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 55px 7% 80px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 120px 7% 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 160px 7% 100px;
		}

		h4 {
			width: 100%;
			color: $black;
			margin-bottom: 25px;
			@include font04;
			@include font-light;

			@media screen and (min-width: $mq-2) {
				margin-bottom: 30px;
			}
			@media screen and (min-width: $mq-3) {
				margin-bottom: 40px;
				@include font07;
			}
			@media screen and (min-width: $mq-4) {
				margin-bottom: 50px;
				@include font08;
			}
			@media screen and (min-width: $mq-5) {
				margin-bottom: 40px;
			}
			@media screen and (min-width: $mq-6) {
				margin-bottom: 50px;
			}
		}
	}

	.ddlLangs {
		@include ddl-common;
		margin-bottom: 20px;
	}
}

// List Component
.listFeatures {
	// max-width: $content-max-width;
	list-style: none;
	margin: 0 auto;
	padding: 0;

	// Common Size Mixin
	// Common Margin Mixin
	li {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;

		// TODO: Generalize Column Behaviours
		@media screen and (min-width: $mq-3) {
			width: calc(50% - 10px);
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 40px;
		}
		@media screen and (min-width: $mq-4) {
			width: calc(33% - 16px);
			margin-bottom: 50px;
		}
		@media screen and (min-width: $mq-6) {
			width: calc(33% - 67px);
		}

		// 2 Columns
		&:nth-child(2n) {
			@media screen and (min-width: $mq-3) {
				margin-right: 0;
				margin-left: 10px;
			}
		}

		// 3 Columns
		&:nth-child(3n + 2) {
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
				margin-left: 0;
			}
		}
		&:nth-child(3n + 1) {
			@media screen and (min-width: $mq-4) {
				margin-right: 24px;
				margin-left: 0;
			}
			@media screen and (min-width: $mq-6) {
				margin-right: 100px;
				margin-left: 0;
			}
		}
		&:nth-child(3n) {
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
				margin-left: 24px;
			}
			@media screen and (min-width: $mq-6) {
				margin-right: 0;
				margin-left: 100px;
			}
		}

		img.iconFeature {
			width: 50px;
			height: 50px;
			margin-right: 25px;
			// background-color: $lightBlue;

			@media screen and (min-width: $mq-2) {
				width: 58px;
				height: 58px;
				margin-right: 22px;
			}
			@media screen and (min-width: $mq-3) {
				width: 63px;
				height: 63px;
				margin-right: 0px;
				margin-bottom: 20px;
			}
			@media screen and (min-width: $mq-4) {
				width: 65px;
				height: 65px;
			}
		}

		.description {
			width: calc(100% - 75px);
			display: flex;
			flex-direction: column;

			@media screen and (min-width: $mq-2) {
				width: calc(100% - 80px);
			}
			@media screen and (min-width: $mq-3) {
				width: 100%;
			}

			h5 {
				@include font01;
				@include font-bold;
				width: 100%;
				color: $black;
				margin-bottom: 10px;
			}
			p {
				@include font01;
				width: 100%;
				color: $black;
			}
		}
	}
}
