@import '../../assets/cascades/utilities';

// Container
section.detail {
	background-color: transparent;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
	width: 90%;
	max-width: $content-max-width;
	margin: 40px 5% 50px;
	z-index: 200;

	@media screen and (min-width: $mq-2) {
		margin: 65px 5% 50px;
	}
	@media screen and (min-width: $mq-3) {
		margin: 70px 7% 80px;
		width: 86%;
	}
	@media screen and (min-width: $mq-4) {
		gap: 20px;
	}
	@media screen and (min-width: $mq-5) {
		margin: 80px auto 120px;
	}

	.Dropdown-control {
		border: none;
		padding: 0;
	}

	.Dropdown-control:hover {
		box-shadow: none;
	}

	.supplier {
		display: flex;
		flex-direction: column;
		background-color: $white;
		width: 100%;
		border-radius: 2px;
		margin-bottom: 20px;

		@media screen and (min-width: $mq-3) {
			margin-bottom: 30px;
		}
		@media screen and (min-width: $mq-4) {
			width: 280px;
			height: fit-content;
		}
		@media screen and (min-width: $mq-5) {
			width: 320px;
		}

		.disclaimer {
			@include font00;
			@include border-box;
			width: 100%;
			padding: 0 15px 30px;
			color: $text-gray;

			@media screen and (min-width: $mq-2) {
				padding: 0 20px 30px;
			}
			@media screen and (min-width: $mq-3) {
				padding: 0 35px 40px;
			}
			@media screen and (min-width: $mq-3) {
				padding: 0 30px 45px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 0 35px 60px;
			}

			&.hidden {
				display: none;

				@media screen and (min-width: $mq-4) {
					display: block;
				}
			}
		}
	}

	.feedback {
		@include border-box;
		display: flex;
		flex-direction: column;
		position: relative;
		background-color: $white;
		width: 100%;
		// padding: 40px 15px;
		padding: 40px 0;

		@media screen and (min-width: $mq-2) {
			// padding: 25px 20px 30px;
			padding: 25px 0 30px;
		}
		@media screen and (min-width: $mq-3) {
			// padding: 50px 35px;
			padding: 50px 0;
		}
		@media screen and (min-width: $mq-4) {
			width: calc(100% - 300px);
			// padding: 45px 55px 70px;
			padding: 45px 0 70px;
		}
		@media screen and (min-width: $mq-5) {
			width: calc(100% - 360px);
			// padding: 45px 70px 70px;
			padding: 45px 0 70px;
		}
		// Same as regular?
		@media screen and (min-width: $mq-6) {
			width: calc(100% - 360px);
			// padding: 45px 80px 70px;
			padding: 45px 0 70px;
		}

		.border {
			border-top: solid 2px $nearlyWhite;
			padding-top: 20px;
		}

		// TODO: Generalize Headers
		h2 {
			@include font06;
			margin: 0 15px 10px;

			@media screen and (min-width: $mq-3) {
				@include font09;
				// width: 500px;
				margin: 0 35px 10px 35px;
				text-align: center;
			}
			@media screen and (min-width: $mq-4) {
				@include font10;
				margin: 0 55px 10px 55px;
			}
			@media screen and (min-width: $mq-5) {
				margin: 0 70px 20px 70px;
			}
			@media screen and (min-width: $mq-6) {
				margin: 0 80px 20px 80px;
			}
		}

		h5 {
			@include font02;
			margin: 10px 15px 15px;

			@media screen and (min-width: $mq-2) {
				margin: 10px 20px 15px;
			}
			@media screen and (min-width: $mq-3) {
				@include font05;
				margin: 10px 35px 20px;
			}
			@media screen and (min-width: $mq-4) {
				margin: 10px 55px 20px;
			}
			@media screen and (min-width: $mq-5) {
				margin: 10px 70px 20px;
			}
			@media screen and (min-width: $mq-6) {
				margin: 10px 80px 20px;
			}

			&.centered {
				@media screen and (min-width: $mq-4) {
					text-align: center;
				}
				@media screen and (min-width: $mq-5) {
					text-align: left;
				}
			}
		}

		.status {
			padding: 10px $base-margin;
			@include font01;
			@include font-bold;
			text-align: center;
			position: absolute;
			// width: 100px;
			left: 15px;
			top: -10px;

			@media screen and (min-width: $mq-2) {
				top: 40px;
				left: auto;
				right: -10px;
			}
			@media screen and (min-width: $mq-3) {
				top: 80px;
				right: -15px;
			}
			@media screen and (min-width: $mq-4) {
				top: 75px;
			}
		}

		.open {
			background-color: $blue;
			color: $white;
		}

		.draft {
			background-color: $bluePassive;
			color: $black;
		}

		.closed {
			background-color: $lightBlue;
			color: $black;
		}

		.description {
			@include font02;
			@include font-light;
			@include border-box;
			margin: 0 15px;
			word-break: break-word;
			margin-bottom: 0;
			border: 2px solid #f0f0f0;
			border-bottom: none;
			padding: 10px;
			margin-bottom: 0;
			padding-bottom: 0;

			@media screen and (min-width: $mq-2) {
				margin: 0 20px;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			@media screen and (min-width: $mq-3) {
				margin: 0 35px;
				padding: 20px;
				text-align: center;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			@media screen and (min-width: $mq-4) {
				margin: 0 55px;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin: 0 70px;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			@media screen and (min-width: $mq-6) {
				margin: 0 80px;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
		.ismore {
			text-decoration: underline;
			cursor: pointer;
			margin-left: 10px;
			&:hover {
				color: $blue;
			}
		}
		.translatewrap {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			// height: 30px;
			margin-bottom: 10px;
			margin: 0 15px;
			word-break: break-word;
			border: 2px solid #f0f0f0;
			border-top: none;
			padding: 10px;
			padding-top: 5px;
			position: relative;
			@media screen and (min-width: $mq-2) {
				margin: 0 20px;
				padding-top: 5px;
			}
			@media screen and (min-width: $mq-3) {
				margin: 0 35px;
				padding: 20px;
				padding-top: 5px;
			}
			@media screen and (min-width: $mq-4) {
				margin: 0 55px;
				padding-top: 5px;
			}
			@media screen and (min-width: $mq-5) {
				margin: 0 70px;
				padding-top: 5px;
			}
			@media screen and (min-width: $mq-6) {
				margin: 0 80px;
				padding-top: 5px;
			}
			.translatetext {
				font-weight: 300;
				margin-right: 10px;
			}
			.translatebutton {
				color: $blue;
				cursor: pointer;
			}
			.backtranslatebutton {
				background: #fff;
				position: absolute;
				bottom: -10px;
				right: 10px;
			}
		}
	}

	// Defined Areas
	.boxHeadSupplier {
		background-color: $nearlyWhite;
		display: flex;
		flex-direction: column;
		position: relative;

		span {
			@include font-bold;
			@include border-box;
			width: 100%;
			padding: 15px 40px 5px 15px;
			color: $black;

			@media screen and (min-width: $mq-2) {
				padding: 25px 50px 5px 20px;
			}
			@media screen and (min-width: $mq-3) {
				padding: 40px 60px 5px 35px;
			}
			@media screen and (min-width: $mq-4) {
				padding: 50px 30px 5px 30px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 50px 35px 5px 35px;
			}
		}

		// TODO: Generalize Headers
		h3 {
			@include font04;
			@include font-light;
			@include border-box;
			width: 100%;
			padding: 0 40px 15px 15px;
			color: $black;

			@media screen and (min-width: $mq-2) {
				padding: 0 50px 25px 20px;
			}
			@media screen and (min-width: $mq-3) {
				@include font07;
				padding: 0 60px 30px 35px;
			}
			@media screen and (min-width: $mq-4) {
				@include font08;
				padding: 0 30px 25px 30px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 0 35px 25px 35px;
			}
		}

		.btnToggleSupplier {
			position: absolute;
			background-color: transparent;
			border: 0;
			right: 5px;
			top: 18px;

			@media screen and (min-width: $mq-2) {
				right: 10px;
				top: 30px;
			}
			@media screen and (min-width: $mq-3) {
				right: 30px;
				top: 55px;
			}
			@media screen and (min-width: $mq-4) {
				display: none;
			}

			img {
				width: 26px;
				height: 26px;
				margin: 7px;

				filter: invert(47%) sepia(97%) saturate(437%) hue-rotate(150deg)
					brightness(97%) contrast(85%);

				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}

			img.expand {
				display: block;
				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}
			img.shrink {
				display: none;
				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}

			&.expanded img.shrink {
				display: block;
				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}
			&.expanded img.expand {
				display: none;
				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}
			// TODO: is this used?
			.moreInfo {
				@include transition(0.3s);
				display: none;
				color: $blue;
				padding: 0;

				&:hover {
					color: $darkBlue;
				}

				@media screen and (min-width: $mq-3) {
					display: block;
				}
			}
			.lessInfo {
				@include transition(0.3s);
				display: none;
				color: $blue;
				padding: 0;

				&:hover {
					color: $darkBlue;
				}
			}

			&.expanded .lessInfo {
				@media screen and (min-width: $mq-3) {
					display: block;
				}
			}
			&.expanded .moreInfo {
				@media screen and (min-width: $mq-3) {
					display: none;
				}
			}
		}
	}

	.boxHeadFeedback {
		position: relative;
		margin: 0 15px 25px;

		@media screen and (min-width: $mq-3) {
			width: 500px;
			margin: 0 auto 20px auto;
		}

		span {
			@include font01;
			@include font-bold;
			display: block;
			margin-bottom: 5px;
			color: $lightGray;
			@media screen and (min-width: $mq-3) {
				text-align: center;
			}
		}

		.cctext {
			@include font02;
			@include font-light;
			display: block;

			@media screen and (min-width: $mq-3) {
				@include font04;
				text-align: center;
			}
			@media screen and (min-width: $mq-4) {
				@include font05;
			}
		}

		h3 {
			@include font04;
			@include font-light;
			display: block;

			@media screen and (min-width: $mq-3) {
				@include font07;
				text-align: center;
			}
			@media screen and (min-width: $mq-4) {
				@include font08;
			}
		}
	}

	.boxJustification {
		display: table;
		border-bottom: solid 2px $nearlyWhite;
		border-top: solid 2px $nearlyWhite;
		padding: 15px 0;
		margin: 20px 15px 20px;

		@media screen and (min-width: $mq-2) {
			margin: 20px 20px 20px;
		}
		@media screen and (min-width: $mq-3) {
			margin: 40px 35px 30px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 5px 0;
			margin: 40px 55px 30px;
		}
		@media screen and (min-width: $mq-5) {
			margin: 40px 70px 30px;
		}
		@media screen and (min-width: $mq-6) {
			margin: 40px 80px 30px;
		}

		p {
			display: table-cell;
			vertical-align: middle;
			padding-left: 20px;
			height: 50px;
		}

		.btnJustify {
			width: 80px;
			height: 50px;
			border: 0;
			margin: 0;
			padding: 0;
			background-color: $white;
			cursor: grab;
			float: right;

			img {
				@include transition(0.3s);
				width: 46px;
				height: 24px;
				margin: 13px 20px 13px 14px;
			}
		}
	}

	.errorMessage {
		display: flex;
		align-items: center;
		margin: 10px 35px 20px;
		.img {
			width: 30px;
			height: 30px;
			margin-right: 20px;
		}
		.title {
			color: $red;
			@include font-medium;
		}
	}

	.boxActions {
		padding: 20px 15px;

		@media screen and (min-width: $mq-2) {
			padding: 20px;
		}
		@media screen and (min-width: $mq-3) {
			display: flex;
			justify-content: space-between;
			padding: 0px 35px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0px 55px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0px 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0px 80px;
		}

		&.mb30 {
			margin-bottom: 30px;
		}

		&.bordered {
			border-bottom: solid 2px $nearlyWhite;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
				padding-bottom: 30px;
			}
			@media screen and (min-width: $mq-5) {
				padding-bottom: 40px;
			}
			@media screen and (min-width: $mq-6) {
				margin-bottom: 40px;
			}
		}

		.btnDeleteArea {
			@include btn-cancel;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 26px;
				margin-bottom: 0px;
				margin-right: auto;
			}
		}

		.btnAddArea {
			@include lnk-common;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 40px;
				line-height: 26px;
				margin-bottom: 5px;
				margin-top: 5px;
			}
		}

		.btnX {
			@include btn-cancel;
			margin-bottom: 20px;
			font-size: 24px;
			background: rgba(154, 154, 154, 0.3) !important;

			@media screen and (min-width: $mq-3) {
				width: 50px;
				height: 50px;
				line-height: 36px;
				margin-bottom: 0px;
				margin-right: auto;
			}
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin-right: auto;
			}
		}

		.btnCancel {
			@include btn-cancel;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
				margin-right: auto;
			}
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin-right: auto;
			}
		}

		.btnSave {
			@include btn-empty;
			margin-bottom: 20px;
			margin-right: 10px;
			padding: 0 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
			}
		}

		.btnPrint {
			@include btn-empty;
			margin-bottom: 20px;
			margin-right: 10px;
			padding: 0 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
			}
		}

		.btnSend {
			@include btn-common;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
				margin-left: 0;
			}
		}
	}

	.boxActions1024 {
		padding: 20px 15px;

		@media screen and (min-width: $mq-2) {
			padding: 20px;
		}
		@media screen and (min-width: $mq-3) {
			display: flex;
			justify-content: space-between;
			padding: 0px 35px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0px 55px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0px 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0px 80px;
		}

		&.mb30 {
			margin-bottom: 30px;
		}

		&.bordered {
			border-bottom: solid 2px $nearlyWhite;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
				padding-bottom: 30px;
			}
			@media screen and (min-width: $mq-5) {
				padding-bottom: 40px;
			}
			@media screen and (min-width: $mq-6) {
				margin-bottom: 40px;
			}
		}

		.wrap1024 {
			display: flex;
		}

		.btnDeleteArea {
			@include btn-cancel;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 26px;
				margin-bottom: 0px;
				margin-right: auto;
			}
		}

		.btnAddArea {
			@include lnk-common;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 40px;
				line-height: 26px;
				margin-bottom: 5px;
				margin-top: 5px;
			}
		}

		.btnX {
			@include btn-cancel;
			margin-bottom: 20px;
			font-size: 24px;
			img {
				width: 20px;
				height: 20px;
			}

			@media screen and (min-width: $mq-3) {
				width: 50px;
				height: 50px;
				line-height: 50px;
				margin-bottom: 0px;
				margin-right: auto;
			}
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin-right: auto;
			}
		}

		.btnCancel {
			@include btn-cancel;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
				margin-right: auto;
			}
			@media screen and (min-width: $mq-4) {
				margin-right: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin-right: auto;
			}
		}

		.btnSave {
			@include btn-empty;
			margin-bottom: 20px;
			margin-right: 10px;
			padding: 0 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
			}
		}

		.btnPrint {
			@include btn-empty;
			margin-bottom: 20px;
			margin-right: 10px;
			padding: 0 20px;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
			}
		}

		.btnSend {
			@include btn-common;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
				margin-left: 0;
			}
		}
	}

	.boxActionsPrint {
		padding: 20px 15px;
		display: flex;
		flex-direction: row-reverse;

		@media screen and (min-width: $mq-2) {
			padding: 20px;
		}
		@media screen and (min-width: $mq-3) {
			display: flex;
			justify-content: space-between;
			padding: 0px 35px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0px 55px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0px 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0px 80px;
		}

		&.bordered {
			border-bottom: solid 2px $nearlyWhite;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
				padding-bottom: 30px;
			}
			@media screen and (min-width: $mq-5) {
				padding-bottom: 40px;
			}
			@media screen and (min-width: $mq-6) {
				margin-bottom: 40px;
			}
		}

		.btnPrint {
			@include btn-common;

			@media screen and (min-width: $mq-3) {
				width: fit-content;
				min-width: 150px;
				height: 50px;
				line-height: 17px;
				margin-bottom: 0px;
				margin-left: 25px;
			}
			@media screen and (min-width: $mq-4) {
				margin-left: 0;
			}
			@media screen and (min-width: $mq-5) {
				margin-left: 25px;
			}
		}
	}

	.boxActionsFlex {
		display: flex;
		justify-content: space-between;
	}

	// unnecessary product of an
	// illustrious margin/border affair
	.boxInputContainer {
		padding: 0 15px;

		@media screen and (min-width: $mq-2) {
			padding: 0 20px;
		}
		@media screen and (min-width: $mq-3) {
			padding: 0 35px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0 55px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0 80px;
		}
	}

	// Lists
	.lstSupplier {
		@include lst-common;
		padding: 20px 15px 0;
		height: auto;

		@media screen and (min-width: $mq-2) {
			padding: 20px 20px 0;
		}
		@media screen and (min-width: $mq-3) {
			padding: 30px 35px 0;
			// height: 650px;

			// Column Behavior
			// Can be generalized
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
			column-fill: balance;
			-moz-column-fill: balance;
			column-gap: 20px;
			-moz-column-gap: 20px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 40px 30px 0;
			height: auto;
			columns: 1;
		}
		@media screen and (min-width: $mq-5) {
			padding: 40px 35px 0;
		}

		&.hidden {
			display: none;

			@media screen and (min-width: $mq-4) {
				display: block;
			}
		}

		&.alt {
			@media screen and (min-width: $mq-2) {
				padding: 0 20px 0;
			}
			@media screen and (min-width: $mq-3) {
				padding: 0 35px 0;
				height: auto;
				columns: unset;
			}
			@media screen and (min-width: $mq-4) {
				padding: 0 30px 0;
			}
			@media screen and (min-width: $mq-4) {
				padding: 0 30px 0;
				height: auto;
				columns: 1;
			}
			@media screen and (min-width: $mq-5) {
				padding: 0 35px 0;
			}
		}

		li {
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;

			span {
				margin-bottom: 10px;
				@include font01;
				@include font-bold;
			}
			p {
				gap: 6px;

				a {
					text-decoration: none;
					color: $blue;
				}
			}
			.tips {
				@include font00;
				@include border-box;
				width: 100%;
				padding: 5px 0 0 0;
				color: $text-gray;
				font-size: 12px;
			}
			.errInput {
				@include transition(0.5s);
				color: $red;
				display: none;
				margin: 5px 0 0 0;

				&.visible {
					display: block;
				}

				@include font00;
			}
		}
		.mb10 {
			margin-bottom: 10px;
		}
		.lh22 {
			line-height: 22px;
		}
	}

	.lstFiles {
		// @include lst-common;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		width: auto;

		@media screen and (min-width: $mq-3) {
			flex-direction: row;
			padding: 0px 0 30px;
			gap: 30px;
			width: 500px;
			margin: 0 auto;
		}

		&.readOnly {
			padding: 20px 15px;
			cursor: pointer;

			@media screen and (min-width: $mq-2) {
				padding: 20px 20px;
			}
			@media screen and (min-width: $mq-3) {
				padding: 35px 35px;
				flex-direction: row;
				// justify-content: center;
			}
			@media screen and (min-width: $mq-4) {
				padding: 35px 55px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 40px 70px;
			}
			@media screen and (min-width: $mq-6) {
				padding: 40px 80px;
			}

			li {
				display: flex;
				// position: relative;
				flex-direction: row;
				align-items: center;
				width: auto;
				margin-bottom: 20px;

				@media screen and (min-width: $mq-3) {
					flex-direction: column;
					align-items: center;
					width: 130px;
				}

				.ext {
					@include icon-ext;
					width: 50px;
					height: 60px;
					margin: 0;
					padding: 0;
				}

				.file {
					// display: table-cell;
					// vertical-align: middle;
					position: relative;
					text-align: left;
					color: $lightGray;
					padding-left: 15px;
					word-break: break-word;
					flex: 1;
					@media screen and (min-width: $mq-3) {
						flex: none;
						width: 130px;
						padding-left: 0;
						margin-top: 15px;
						text-align: center;
						overflow: hidden;
						height: 42px;
						line-height: 21px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					// Put 3dots to all
					// &::before {
					// 	@media screen and (min-width: $mq-3) {
					// 		content: "...";
					// 		position: absolute;
					// 		bottom: 0;
					// 		right: 0;
					// 	}
					// }

					// // Hide dots if not necessary
					// &::after {
					// 	@media screen and (min-width: $mq-3) {
					// 		content: "";
					// 		position: absolute;
					// 		right: 0;
					// 		width: 1rem;
					// 		height: 24px;
					// 		background: white;
					// 	}
					// }
				}
			}
		}

		&.empty {
			padding: 0;
			height: 0;
		}

		li {
			display: flex;
			position: relative;
			width: 100%;

			.ext {
				@include icon-ext;
				display: block;
				margin: 0;
				padding: 0;

				@media screen and (min-width: $mq-3) {
					width: 50px;
					height: 60px;
				}
			}

			// TODO: obsolete?
			// .details {
			// 	width: calc(100% - 65px);

			// 	@media screen and (min-width: $mq-3) {
			// 		width: calc(100% - 80px);
			// 	}

			// 	.name {
			// 		display: block;
			// 		color: $black;
			// 		min-width: 200px;
			// 		padding-left: 15px;
			// 		margin-bottom: 5px;

			// 		@media screen and (min-width: $mq-3) {
			// 			padding-top: 5px;
			// 			padding-left: 30px;
			// 		}
			// 	}
			// 	.size {
			// 		display: block;
			// 		color: $black;
			// 		padding-left: 15px;
			// 		width: 100%;
			// 		overflow: hidden;

			// 		@media screen and (min-width: $mq-3) {
			// 			padding-left: 30px;
			// 		}
			// 	}
			// 	.btnCancelFile {
			// 		@include icon-cancel;
			// 		position: absolute;
			// 		right: -11px;
			// 		top: 8px;

			// 		@media screen and (min-width: $mq-3) {
			// 			top: 12px;
			// 		}
			// 	}
			// }
		}
	}

	.lstFilesCenter {
		// @include lst-common;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		width: auto;

		@media screen and (min-width: $mq-3) {
			flex-direction: row;
			padding: 0px 0 30px;
			gap: 30px;
			width: 500px;
			margin: 0 auto;
			justify-content: center;
		}

		&.readOnly {
			padding: 20px 15px;
			cursor: pointer;

			@media screen and (min-width: $mq-2) {
				padding: 20px 20px;
			}
			@media screen and (min-width: $mq-3) {
				padding: 35px 35px;
				flex-direction: row;
				// justify-content: center;
			}
			@media screen and (min-width: $mq-4) {
				padding: 35px 55px;
			}
			@media screen and (min-width: $mq-5) {
				padding: 40px 70px;
			}
			@media screen and (min-width: $mq-6) {
				padding: 40px 80px;
			}

			li {
				display: flex;
				// position: relative;
				flex-direction: row;
				align-items: center;
				width: auto;
				margin-bottom: 20px;

				@media screen and (min-width: $mq-3) {
					flex-direction: column;
					align-items: center;
					width: 130px;
				}

				.ext {
					@include icon-ext;
					width: 50px;
					height: 60px;
					margin: 0;
					padding: 0;
				}

				.file {
					// display: table-cell;
					// vertical-align: middle;
					position: relative;
					text-align: left;
					color: $lightGray;
					padding-left: 15px;
					word-break: break-word;
					flex: 1;
					@media screen and (min-width: $mq-3) {
						flex: none;
						width: 130px;
						padding-left: 0;
						margin-top: 15px;
						text-align: center;
						overflow: hidden;
						height: 42px;
						line-height: 21px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					// Put 3dots to all
					// &::before {
					// 	@media screen and (min-width: $mq-3) {
					// 		content: "...";
					// 		position: absolute;
					// 		bottom: 0;
					// 		right: 0;
					// 	}
					// }

					// // Hide dots if not necessary
					// &::after {
					// 	@media screen and (min-width: $mq-3) {
					// 		content: "";
					// 		position: absolute;
					// 		right: 0;
					// 		width: 1rem;
					// 		height: 24px;
					// 		background: white;
					// 	}
					// }
				}
			}
		}

		&.empty {
			padding: 0;
			height: 0;
		}

		li {
			display: flex;
			position: relative;
			width: 100%;

			.ext {
				@include icon-ext;
				display: block;
				margin: 0;
				padding: 0;

				@media screen and (min-width: $mq-3) {
					width: 50px;
					height: 60px;
				}
			}

			// TODO: obsolete?
			// .details {
			// 	width: calc(100% - 65px);

			// 	@media screen and (min-width: $mq-3) {
			// 		width: calc(100% - 80px);
			// 	}

			// 	.name {
			// 		display: block;
			// 		color: $black;
			// 		min-width: 200px;
			// 		padding-left: 15px;
			// 		margin-bottom: 5px;

			// 		@media screen and (min-width: $mq-3) {
			// 			padding-top: 5px;
			// 			padding-left: 30px;
			// 		}
			// 	}
			// 	.size {
			// 		display: block;
			// 		color: $black;
			// 		padding-left: 15px;
			// 		width: 100%;
			// 		overflow: hidden;

			// 		@media screen and (min-width: $mq-3) {
			// 			padding-left: 30px;
			// 		}
			// 	}
			// 	.btnCancelFile {
			// 		@include icon-cancel;
			// 		position: absolute;
			// 		right: -11px;
			// 		top: 8px;

			// 		@media screen and (min-width: $mq-3) {
			// 			top: 12px;
			// 		}
			// 	}
			// }
		}
	}

	.morefiles {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
		@media screen and (min-width: $mq-3) {
			justify-content: center;
			padding-left: 0;
		}
		.downicon {
			width: 20px;
			height: 20px;
			cursor: pointer;
			img {
				margin-top: 4px;
				filter: invert(60%) sepia(89%) saturate(2134%) hue-rotate(161deg)
					brightness(88%) contrast(85%);
			}
		}
		.downicontext {
			@include font01;
			margin-left: 20px;
			cursor: pointer;
		}
	}

	.lstFeedback {
		@include lst-common;
		padding: 0 15px;

		@media screen and (min-width: $mq-2) {
			padding: 0 20px;
		}
		@media screen and (min-width: $mq-3) {
			padding: 0 35px;
		}
		@media screen and (min-width: $mq-4) {
			padding: 0 55px;
		}
		@media screen and (min-width: $mq-5) {
			padding: 0 70px;
		}
		@media screen and (min-width: $mq-6) {
			padding: 0 80px;
		}

		&.bordered {
			border-bottom: solid 2px $nearlyWhite;
			margin-bottom: 20px;
			padding-bottom: 10px;

			@media screen and (min-width: $mq-4) {
				margin-bottom: 30px;
			}
			@media screen and (min-width: $mq-6) {
				padding-bottom: 20px;
				margin-bottom: 40px;
			}
		}

		li {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			@media screen and (min-width: $mq-3) {
				margin-bottom: 30px;
			}
			@media screen and (min-width: $mq-5) {
				margin-bottom: 40px;
			}

			span {
				margin-bottom: 10px;

				@media screen and (min-width: $mq-5) {
					margin-bottom: 20px;
				}
			}

			.errInput {
				@include transition(0.5s);
				color: $red;
				display: none;
				margin: 5px 0 0 0;

				&.visible {
					display: block;
				}

				@include font00;
			}
		}
	}

	// Inputs
	.txbSupplier {
		@include txb-common;
		margin-top: 10px;
	}

	.txbFeedback {
		@include txb-common;
	}

	.txbSupplier-noborder {
		@include txb-common-noborder;
		margin-top: 10px;
	}

	.txbFeedback-noborder {
		@include txb-common-noborder;
	}

	.dtpFeedback {
		@include dtp-common;
	}

	.txaSupplier {
		@include txa-common;
		margin-top: 10px;
	}

	.txaFeedback {
		@include txa-common;
		min-height: 108px;
		padding: 17px 20px;
	}

	.txaSupplier-noborder {
		@include txa-common-noborder;
		margin-top: 10px;
	}

	.txaFeedback-noborder {
		@include txa-common-noborder;
		min-height: 108px;
		padding: 17px 20px;
	}

	.ddlFeedback {
		@include ddl-common;
	}

	.ddlFeedbackNofilter {
		@include ddl-common-no-filter;
	}
}

.cancelModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			width: 564px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px;

				@media screen and (min-width: $mq-2) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.info {
					@include font-light;
					color: $black;
					font-size: 16px;
					line-height: 22px;
				}
			}
			.buttongroup {
				margin-top: 30px;
				display: flex;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}

.tanslateModal {
	position: relative;
	.ant-modal-content {
		@media screen and (min-width: $mq-2) {
			// width: 90%;
		}
		@media screen and (min-width: $mq-3) {
			// width: 516px;
		}
		@media screen and (min-width: $mq-4) {
			// width: 700px;
		}
		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 60px 20px;

				@media screen and (min-width: $mq-3) {
					padding: 60px 36px;
				}
				.title {
					@include font-light;
					// height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.selectlang {
					color: rgba(51, 51, 51, 1);
					font-size: 16px;
					height: 22px;
					font-weight: 700;
					font-style: normal;
					letter-spacing: 0px;
					text-align: left;
					line-height: 22px;
				}
				.info {
					@include font-light;
					color: $black;
					font-size: 16px;
					line-height: 22px;
				}
			}
			.langdrop {
				margin-top: 10px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				@media screen and (min-width: $mq-3) {
					flex-direction: row;
				}
				.ddlFeedbackNofilter {
					@include ddl-common-no-filter;
				}
				.langbutton {
					@include transition(0.3s);
					width: 100%;
					margin-bottom: 10px;
					overflow: hidden;
					@media screen and (min-width: $mq-3) {
						width: calc((100% - 100px) / 5);
						margin-right: 20px;
					}
					border-radius: 25px;
					border: solid 2px #7c7c7c;
					text-align: center;
					color: #7c7c7c;
					height: 50px;
					text-decoration: none;
					cursor: pointer;
					line-height: 46px;
					font-size: 16px;
					font-weight: 700;
					background-color: #fff;
					&.active {
						border: solid 2px $blue;
						color: $blue;
					}
					&:hover {
						border: solid 2px $blue;
						color: $blue;
					}
				}
			}
			.langbuttonwrap {
				margin-top: 30px;
				.langbutton {
					@include btn-common;
					@media screen and (min-width: $mq-3) {
						width: fit-content;
						width: 100%;
						height: 50px;
						line-height: 17px;
						margin-bottom: 0px;
						margin-left: 0;
					}
				}
			}
		}
	}
}
.ant-modal-mask {
	background: $background-darkest;
}

.printModal {
	position: relative;
	.ant-modal-content {
		width: 100%;

		.closeicon {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.ant-modal-body {
			padding: 0;
			.contentinto {
				padding: 30px 0;
				.title {
					@include font-light;
					height: 34px;
					font-size: 32px;
					line-height: 34px;
					color: $black;
					margin-bottom: 30px;
				}
				.info {
					@include font-light;
					color: $black;
					font-size: 16px;
					line-height: 22px;
				}
			}
			.buttongroup {
				display: flex;
				width: 210mm;
				flex-direction: row-reverse;
				margin: 30px auto 0 auto;
				.btnCancel {
					@include transition(0.3s);
					width: 130px;
					border-radius: 2px;
					border: solid 2px $blue;
					background-color: $white;
					text-align: center;
					color: $blue;
					height: 46px;
					text-decoration: none;
					cursor: pointer;
					line-height: 42px;
					font-size: 16px;

					&:hover {
						background-color: $lightBlue;
					}
				}
				.btnLogin {
					@include transition(0.3s);
					width: 130px;
					display: block;
					background-color: $blue;
					border-radius: 2px;
					color: $white;
					border: solid 2px $blue;
					text-align: center;
					height: 46px;
					line-height: 42px;
					cursor: pointer;
					font-size: 16px;
					margin-left: 16px;

					&:hover {
						background-color: $darkBlue;
						border: solid 2px $darkBlue;
					}
				}
			}
		}
	}
}

.ant-zoom-enter,
.ant-zoom-appear {
	-webkit-animation-duration: $animation-time;
	animation-duration: $animation-time;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-zoom-leave {
	-webkit-animation-duration: $animation-time;
	animation-duration: $animation-time;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
	-webkit-animation-name: antZoomIn;
	animation-name: antZoomIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
	-webkit-animation-name: antZoomOut;
	animation-name: antZoomOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
	transform: scale(0);
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
	transform: none;
}
.ant-zoom-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}
@keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}

.ant-fade-enter,
.ant-fade-appear {
	-webkit-animation-duration: $animation-time;
	animation-duration: $animation-time;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-fade-leave {
	-webkit-animation-duration: $animation-time;
	animation-duration: $animation-time;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
	-webkit-animation-name: antFadeIn;
	animation-name: antFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
	-webkit-animation-name: antFadeOut;
	animation-name: antFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
	opacity: 0;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
.ant-fade-leave {
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
